<template>
  <div class="arrow relative reduceto09emOnMobile" @click="goBack">
    <div class="arrow-top"></div>
    <div class="arrow-bottom"></div>
  </div>
</template>

<script lang="ts" setup>
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  const store = useStore();
  const router = useRouter();

  const goBack = () => {
    store.commit('SET_DIRECTION', 'back');
    router.back();
  };
</script>

<style lang="scss" scoped>
  .arrow {
    cursor: pointer;
    width: 2em;
    height: 3em;
    transition: transform 0.1s;
    $transition-time: 0.15s;

    &-top,
    &-bottom {
      border-radius: 3px;
      background-color: #d5d5d5;
      height: 3px;
      left: 0;
      position: absolute;
      width: 2em;

      &:after {
        content: '';
        background-color: #b2b2b2;
        height: 100%;
        position: absolute;
        top: 0;
        transition: all $transition-time;
      }
    }

    &-top {
      transform: rotate(45deg);
      transform-origin: bottom left;
      top: 49%;

      &:after {
        right: 100%;
        left: 0;
        transition-delay: 0s;
      }
    }

    &-bottom {
      top: 53%;
      transform: rotate(-45deg);
      transform-origin: top left;

      &:after {
        right: 0;
        left: 100%;
        transition-delay: $transition-time;
      }
    }

    &:hover & {
      &-top:after {
        right: 0;
        transition-delay: $transition-time;
      }

      &-bottom:after {
        left: 0;
        transition-delay: 0s;
      }
    }

    &:active {
      transform: scale(0.8);
    }
  }
</style>
