<template>
  <div class="flex flex-col justify-center text-center mx-auto my-3 customW">
    <img src="/image2/systemFail.png" />
    <h2 class="mx-auto mt-5 text-2xl lg:text-4xl text-center text-bodyDark">
      {{ i18n.getTranslation('SYSTEM_ERROR') }}
    </h2>
  </div>
</template>

<script lang="ts" setup>
import { injectStrictI18n } from '../../src/plugins/i18n';

const i18n = injectStrictI18n('i18n');
</script>
<style lang="scss" scoped>
.customW {
  @media (max-width: 699px) {
    max-width: 16rem;
  }
  @media (min-width: 699px) and (max-width: 1360px) {
    max-width: 25rem;
  }
  @media (min-width: 1360px) {
    max-width: 35rem;
  }
}
</style>
