import type { LanguageService } from '@core/i18n.service';
import { i18n } from '@core/i18n.service';
import { inject } from 'vue';
import type { App } from 'vue';

export function injectStrictI18n(key: string) {
  const resolved = inject(key);
  if (!resolved) {
    throw new Error(`Could not resolve ${key}`);
  }

  return resolved as LanguageService;
}

const translationsPlugin = {
  install(app: App): void {
    app.config.globalProperties.i18n = i18n;
    app.provide('i18n', i18n);
  },
};

export default translationsPlugin;
// getTranslation: string | keyedString | keyedStringWithArrays | keyedStringWithArrays[] | undefined
// getDynamicTranslation: string | keyedString | keyedStringWithArrays | keyedStringWithArrays[] | undefined
