import { createStore, createLogger } from 'vuex';
// import VuexPersist from 'vuex-persist';

import { userService } from './core/user.service';

import { postData } from '@adapters/apiHelper';
import type { Whitelabel } from '@adapters/white-label-web.repository';

// import { awaitForTasks } from './core/tasks.service';
// import VuexPersistence from 'vuex-persist';
import type { APIErrorType } from '@core/base/errors';

// const vuexLocalPersist = new VuexPersistence({
//   key: 'persisted',
//   storage: window.localStorage,
//   modules: ['dashboard'],
// });

// Vue.use(Vuex);

interface Modal {
  modalId: null | string;
  modalWidth: null | string;
}

const modalState = {
  state: {
    modalId: null,
    modalWidth: null,
  } as Modal,
  getters: {
    GET_MODAL_STATE: (state) => {
      return state.modalId;
    },
    GET_MODAL_WIDTH: (state) => {
      return state.modalWidth;
    },
  },
  mutations: {
    SET_MODAL_STATE: (state, value: string): void => {
      state.modalId = value;
    },
    SET_MODAL_WIDTH: (state, value: string): void => {
      state.modalWidth = value;
    },
  },
  actions: {
    hideModal({ commit, state }: unknown): void {
      commit('SET_MODAL_STATE', null);
      commit('SET_MODAL_WIDTH', null);
    },
  },
};

const styles = {
  state: { whitelabel: null as Whitelabel },
  getters: {
    GET_WHITELABEL: (state): Whitelabel => {
      return state.whitelabel;
    },
  },
  mutations: {
    SET_WHITELABEL: (state, value: string): void => {
      state.whitelabel = value;
    },
  },
  actions: {},
};

const key = {
  state: {
    key: null,
  },
  getters: {
    GET_KEY: (state: any) => {
      const url = new URL(window.location.href);
      const hasQueryKey = url.searchParams.get('userKey');

      if (hasQueryKey?.length) {
        return hasQueryKey;
      }

      const sessionStor = window.sessionStorage.getItem('Key');
      const localStor = window.localStorage.getItem('Key');

      return state.key ? state.key : sessionStor ?? localStor;
    },
  },
  mutations: {
    SET_KEY: (state: any, value: string): void => {
      state.key = value;
      window.sessionStorage.setItem('Key', value);
      window.localStorage.setItem('Key', value);
    },
  },
};

const onboardModule = {
  state: {
    direction: 'forward',
    scrollPos: 0,
    showHubspotBookModal: false,
    showModalVar: false,
    showErrorModalVar: false,
    showModalFooter: false,
    showLoading: false,
    showProgressBar: true,
    switchableHeaders: [],
    headers: {
      header: '',
      smallerHeader: '',
      aboveCardsHeader: '',
    },
    responseData: {
      errors: [],
      formData: {
        cardIsSelected: false,
        enableSubmitBtn: false,
        baseImg: '',
        fillImg: '',
        showBackBtn: false,
        isSlider: false,
        isCard: false,
        isPosting: false,
        btnTxt: '',
        retirementPage: [
          {
            id: 'Card1',
            index: 0,
            value: 'value1',
            checked: false,
            cardTxt: '',
          },
          {
            id: 'Card2',
            index: 1,
            value: 'value2',
            checked: false,
            cardTxt: '',
          },
        ],
        signupPage: [
          {
            name: 'Navn',
            value: '',
            placeholder: '',
            validationType: 'name',
          },
          {
            name: 'Mail',
            value: '',
            placeholder: '',
            validationType: 'email',
            inputMode: 'email',
          },
        ],
        incomePage: {
          name: 'Income',
          value: '',
          placeholder: '',
          validationType: 'income',
        },
        inputCpr: {
          name: 'Cpr',
          value: '',
          placeholder: '',
          validationType: 'cpr',
        },
        detailsPage: [
          {
            id: 'Card1',
            index: 0,
            value: 'value1',
            checked: false,
            cardTxt: '',
          },
          {
            id: 'Card2',
            index: 1,
            value: 'value2',
            checked: false,
            cardTxt: '',
          },
          {
            id: 'Card3',
            index: 2,
            value: 'value3',
            checked: false,
            cardTxt: '',
          },
        ],
        dreamsPage: [
          {
            id: 'Card4',
            index: 0,
            value: 'value1',
            checked: false,
            cardTxt: '',
          },
          {
            id: 'Card5',
            index: 1,
            value: 'value2',
            checked: false,
            cardTxt: '',
          },
          {
            id: 'Card6',
            index: 2,
            value: 'value3',
            checked: false,
            cardTxt: '',
          },
        ],
        pensionAgePage: {
          connect: [true, false],
          range: { min: 60, max: 75 },
          sliderId: 'PensureAge',
          sliderTxt: 'år',
          snap: false,
          start: [67],
          step: 1,
          position: 'top',
        },
      },
      key: '',
    },
  },
  getters: {
    GET_HEADERS: (state: any) => {
      return state.headers;
    },
    GET_DIRECTION: (state: any) => {
      return state.direction;
    },
    GET_SHOW_HUBSPOT_BOOK_MODAL: (state: any) => {
      return state.showHubspotBookModal;
    },
    GET_SHOW_PROGRESS_BAR: (state: any) => {
      return state.showProgressBar;
    },
    GET_ERROR: (state: any) => {
      // if (state.responseData.errors) {
      return state.responseData.errors;
      // } else {
      //   return { '': [] };
      // }
    },
    GET_FORM_DATA: (state: any) => {
      return state.responseData.formData;
    },
  },
  mutations: {
    // REMOVE_PAGE_KEY: (state: any, value: string): void => {
    //    delete state.pageKeys[value];
    // },
    SET_HEADERS: (state: any, value: Record<string, unknown>): void => {
      state.headers = Object.assign(state.headers, value);
    },
    SET_RADIO_BTN_DATA: (state: any, data: any): void => {
      state.responseData.formData[data.name].forEach((el: any) => {
        el.checked = el.value === data.value;
      });
      //state.responseData.formData[data.name][data.value.index].checked = data.value.checked;
      //state.responseData.formData = Object.assign({}, state.responseData.formData, value);
    },
    SET_FORM_DATA: (state: any, value: Record<string, unknown>): void => {
      state.responseData.formData = Object.assign(
        state.responseData.formData,
        value
      );
    },
    SET_SHOW_HUBSPOT_BOOK_MODAL: (state: any, value: boolean): void => {
      state.showHubspotBookModal = value;
    },
    SET_SHOW_PROGRESS_BAR: (state: any, value: boolean) => {
      state.showProgressBar = value;
    },
    SET_ERROR: (state: any, value: APIErrorType[]): void => {
      state.responseData.errors = value;
    },
    SET_DIRECTION: (state: any, value: string): void => {
      state.direction = value;
    },
  },
};

const dashboardModule = {
  state: () => ({
    isDashboardReloaded: false,
    user: {
      skipNemId: false,
      skipCprNumber: false,
      skipPensionsInfo: false,
      email: '',
      totalSavings: null,
      yearlyPayment: null,
      retirementPreferences: {
        wantedPensionAge: {},
        wantedPensionIncome: {},
        wantedRisk: {},
      },
      firstName: '',
      lastName: '',
    },
    skipAnimations: {
      skipPreferencesAnim: false,
    },
    uiState: {
      pensurePensionIsClicked: false,
      taskDrawerIsOpen: false,
      teasePremiumModalIsOpen: false,
      notification: {},
      showNotification: false,
      shownNotifications: [],
      expandedInsuranceOverviewComp: null,
      subrouteTabsVisibility: null,
    },
    gauge: {
      score: [],
    },
    meetings: {
      newMeetings: [],
      previousMeetings: [],
    },
    showHubspotMeetings: false,
    taskList: [],
    chartData: {},
    navNotification: false,
    history: '',
    lastReadMsg: 0,
    showMsgPopup: false,
    voucher: null,
  }),
  getters: {
    GET_IS_DASHBOARD_RELOADED: (state: any) => {
      return state.isDashboardReloaded;
    },
    GET_FIRSTNAME: (state: any) => {
      return state.user.firstName;
    },
    GET_LASTNAME: (state: any) => {
      return state.user.lastName;
    },
    GET_MEETINGS: (state: any) => {
      return state.meetings;
    },
    GET_UI_STATE: (state: any) => {
      return state.uiState;
    },
    GET_EMAIL: (state: any, rootState: any) => {
      let mail = '';
      rootState.GET_FORM_DATA.signupPage.forEach((el: FormData) => {
        if (el.name === 'Mail' && el.value) {
          // console.log(el.value);
          mail = el.value;
        }
      });
      return mail;
    },
    GET_RETIREMENT_AGE: (state: any) => {
      return state.user.retirementAge;
    },
    GET_SKIP_ANIMATIONS: (state: any) => {
      return state.skipAnimations;
    },
    GET_GAUGE: (state: any) => {
      return state.gauge;
    },
    GET_USER: (state: any) => {
      return state.user;
    },
    GET_PREFERENCES_AMOUNT: (state: any) => {
      return state.user.retirementPreferences.wantedPensionIncome.value;
    },
    GET_PREFERENCES_RISK: (state: any) => {
      return state.user.risk;
    },
    GET_TASKS: (state: any) => {
      return state.taskList;
    },
    GET_CHART_DATA: (state: any) => {
      return state.chartData;
    },
    GET_NAV_NOTIFICATION: (state: any) => {
      return state.navNotification;
    },
    GET_HISTORY: (state: any) => {
      return state.history;
    },
    GET_LAST_READ: (state: any) => {
      return state.lastReadMsg;
    },
    GET_SHOW_MSG_POPUP: (state: any) => {
      return state.showMsgPopup;
    },
    GET_SHOW_NOTIFICATION_MODAL: (state: any) => {
      return state.showNotification;
    },
    GET_NOTIFICATIONS_SHOWN: (state: any) => (name: string) => {
      return (
        state.uiState.shownNotifications.find((x: string) => x === name) ??
        false
      );
    },
    GET_NOTIFICATION_MODAL: (state: any) => {
      if (state.notification) {
        return state.notification;
      } else {
        return {};
      }
    },
    GET_VOUCHER: (state: any) => {
      return state.voucher;
    },
  },
  mutations: {
    SET_IS_DASHBOARD_RELOADED: (state: any, value: boolean) => {
      state.isDashboardReloaded = value;
    },
    SET_EMAIL: (state: any, value: string): void => {
      state.user.email = value;
    },
    SET_MEETINGS: (state: any, value: string): void => {
      state.meetings = value;
    },
    SET_UI_STATE: (
      state: any,
      newPropertyValue: Record<string, unknown>
    ): void => {
      state.uiState = Object.assign(state.uiState, newPropertyValue);
    },
    SET_USER_PROPS: (
      state: any,
      newPropertyValue: Record<string, unknown>
    ): void => {
      state.user = Object.assign(state.user, newPropertyValue);
    },
    SET_GAUGE: (
      state: any,
      newPropertyValue: Record<string, unknown>
    ): void => {
      state.gauge = Object.assign(state.gauge, newPropertyValue);
    },
    SET_GAUGE_SCORE: (state: any, value: Record<string, unknown>): void => {
      if (state.gauge.score.length > 1) {
        state.gauge.score.pop();
      }
      state.gauge.score.unshift(value);
    },
    SET_SKIP_ANIMATIONS: (
      state: any,
      newPropertyValue: Record<string, unknown>
    ): void => {
      state.skipAnimations = Object.assign(
        state.skipAnimations,
        newPropertyValue
      );
    },
    SET_FIRST_NAME: (state: any, value: number): void => {
      state.user.firstName = value;
    },
    SET_LAST_NAME: (state: any, value: number): void => {
      state.user.lastName = value;
    },
    SET_RETIREMENT_AGE: (
      state: any,
      value: number | ValueAndRange<number>
    ): void => {
      state.user.retirementPreferences.wantedPensionAge.value = value;
    },
    SET_PREFERENCES_AMOUNT: (state: any, value: number): void => {
      state.user.retirementPreferences.wantedPensionIncome.value = value;
    },
    SET_PREFERENCES_RISK: (state: any, value: number): void => {
      state.user.retirementPreferences.wantedRisk.value = value;
    },
    SET_TASKS: (state: any, value: Array<any>): void => {
      state.taskList = value;
    },
    SET_CHART_DATA: (state: any, value: Record<string, unknown>): void => {
      state.chartData = value;
    },
    SET_NAV_NOTIFICATION: (state: any, value: boolean) => {
      state.navNotification = value;
    },
    SET_HISTORY: (state: any, value: string) => {
      state.history = value;
    },
    SET_LAST_READ: (state: any, value: number) => {
      state.lastReadMsg = value;
    },
    SET_SHOW_MSG_POPUP: (state: any, value: boolean) => {
      state.showMsgPopup = value;
    },
    SET_NOTIFICATION_MODAL: (state: any, value: any) => {
      state.notification = value;
    },
    SET_SHOW_NOTIFICATION_MODAL: (state: any, value: boolean) => {
      state.showNotification = value;
    },
    SET_VOUCHER: (state: any, voucher: string) => {
      state.voucher = voucher;
    },
    SET_NOTIFICATIONS_SHOWN: (state: any, value: string) => {
      if (state.uiState.shownNotifications.includes(value)) {
        return;
      }
      state.uiState.shownNotifications.push(value);
    },
  },
  actions: {
    //async fetchTasks({ commit, state }: { commit: any; state: any }) {
    //  const data = (await getTasks(state.key)) as any;
    //  // console.log('data', data);
    //  if (data && data.hasTasks) {
    //    // console.log(data);
    //    commit('SET_TASKS', data.tasks);
    //  } else if (data && !data.hasTasks) {
    //    commit('SET_TASKS', []);
    //  }
    //},
    //async getTasks({ commit, state }: { commit: any; state: any }) {
    //  const res = await awaitForTasks(state.key);
    //  if (res && res.isSuccess) {
    //    commit('SET_TASKS', res.data);
    //  }
    //},
    async getPreferences({ commit, state }: { commit: any; state: any }) {
      const res = await userService.getPreferences();
      if (res) {
        const { wantedPensionAge, wantedPensionIncome, wantedRisk } = res;
        commit('SET_RETIREMENT_AGE', wantedPensionAge);
        commit(
          'SET_PREFERENCES_AMOUNT',
          (wantedPensionIncome.value as number) || wantedPensionIncome.min
        );
        commit('SET_PREFERENCES_RISK', wantedRisk.value || wantedRisk.min);
      }
    },
    async updatePreferences(
      { commit, state }: { commit: any; state: any },
      obj: any
    ) {
      const res = await userService.updatePreferences(state.key, obj);
      if (res && res.isSuccess) {
        const { retirementAge, amount, risk } = obj;
        if (retirementAge) {
          commit('SET_RETIREMENT_AGE', retirementAge);
        }
        if (amount) {
          commit('SET_PREFERENCES_AMOUNT', amount);
        }
        if (risk) {
          commit('SET_PREFERENCES_RISK', risk);
        }
      } else {
        console.error(res);
      }
    },
    async fetchUser({ commit, state }: { commit: any; state: any }, obj: any) {
      if (state && state.key) {
        const data = await userService.getUser();
        if (data) {
          commit('SET_EMAIL', data.email);
          commit('SET_FIRST_NAME', data.firstName);
          commit('SET_LAST_NAME', data.lastName);
          commit('SET_USER_PROPS', data);
          return data;
        }
        throw new Error('Undefined?');
      }
      return null;
    },
    async registerPremiumFromVoucher(
      { commit, state }: { commit: any; state: any },
      voucher: string
    ) {
      if (state && state.key) {
        if (voucher) {
          await postData('/api/dashboard/voucher/register', { voucher });
        }
      }
    },
  },
};

export const store = createStore({
  //no existing compatabilty with vue3
  //plugins: [vuexLocalPersist.plugin],
  modules: {
    styles,
    onboard: onboardModule,
    dashboard: dashboardModule,
    modalState,
    key,
  },
});

export interface State {
  onboard: typeof onboardModule;
  dashboard: typeof dashboardModule;
  whitelabel: typeof styles;
  modalState: typeof modalState;
  key: typeof keyState;
}
