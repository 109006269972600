import type { APIResponse } from "@core/models/apiResponse.model";
import { post, responseError } from "../../custom/Methods";
import type {
  UserAuthModel,
  UserInfoModel,
  PreferencesModel,
} from "@core/models/user.model";
import type { MeetingsModel } from "@core/ports/meetings.repository";
import type { CookiesModel } from "../core/models/cookies.model";
import { store } from "../store";
import type {
  ScoreGaugeCards,
  TaskOverrule,
} from "../core/ports/tasks.repository";
import type {
  PensionInfo,
  PensurePensionGraph,
  InsuranceType,
  PensionSchemeResponse,
  PensionInfoGroup,
} from "@core/ports/pensionPlans.repository";
import type { APIErrorType } from "@core/base/errors";
import { APIError, AuthorizationError } from "@core/base/errors";
import type {
  keyedString,
  keyedStringWithArrays,
} from "@core/base/helperTypes";
import type { SavingsGoalModel } from "@core/models/savings-goal.model";

export enum Endpoint {
  Onboarding = "/api/dashboard/onboarding/",
  UserInfo = "/api/dashboard/user",
  AuthState = "/api/dashboard/user/authstate",
  PostMitIdParameters = "/api/dashboard/onboarding/login/parameters",
  PostCprParameters = "/api/dashboard/onboarding/login/validate",
  DeleteUser = "/api/dashboard/user/remove",
  Logout = "api/dashboard/logout",
  TasksList = "/api/dashboard/tasks",
  UserPreferences = "/api/dashboard/dreamscard",
  Meetings = "/api/hubspot/meetings",
  Blogs = "/api/hubspot/blogs",
  Insurances = "/api/dashboard/pensionsinfo/insurances",
  InsurancesDetailed = "/api/dashboard/pensionsinfo/insurance-list-amounts",
  InsurancesUse = "/api/dashboard/pensionsinfo/insurance-incidentreport-list",
  InsuranceHealth = "/api/dashboard/pensionsinfo/health-insurance",
  ListInsurances = "/api/dashboard/pensionsinfo/insurance-list",
  Agreements = "/api/dashboard/pensionsinfo/agreements",
  PensionPlans = "/api/dashboard/pensionsinfo/pensionplantable",
  PensionPlansGraph = "/api/dashboard/pensionsinfo/pensiongraph",
  PensurePensionGraph = "/api/dashboard/pensure/pension/pensiongraph",
  PensurePensionPlan = "/api/dashboard/pensure/pension/details",
  PensurePensionReturns = "/api/dashboard/pensure/pension/returns",
  AllPensionsInfoTables = "/api/dashboard/pensionsinfo/tables",
  PensionsInfoStatus = "/api/dashboard/pensionsinfo/upload/status",
  Profile = "/api/dashboard/user/profile",
  Insights = "/api/dashboard/insights",
  Score = "/api/dashboard/insights/score",
  InsightsList = "/api/dashboard/insights/list",
  Overrule = "/api/dashboard/insights/score/overrule",
  ListPensionTrendsTable = "/api/dashboard/pensionsinfo/schemes",
  Cookies = "/api/dashboard/cookieList",
  SavingsGoal = "/api/dashboard/pensionsinfo/savingsgraph",
  Whitelabeling = "/admin/inc/config.json",
}

interface APIResponseMap {
  [Endpoint.UserInfo]: UserInfoModel;
  [Endpoint.MitIdParameters]: any;
  [Endpoint.PostMitIdParameters]: any;
  [Endpoint.AuthState]: UserAuthModel;
  [Endpoint.Logout]: void;
  [Endpoint.DeleteUser]: any;
  [Endpoint.UserPreferences]: PreferencesModel;
  [Endpoint.Meetings]: MeetingsModel[];
  [Endpoint.TasksList]: any;
  [Endpoint.Blogs]: unknown;
  [Endpoint.Insurances]: PensionInfo;
  [Endpoint.PensionPlans]: PensionInfoGroup;
  [Endpoint.PensurePensionGraph]: PensurePensionGraph;
  [Endpoint.Agreements]: PensionSchemeResponse;
  [Endpoint.PensionPlansGraph]: unknown;
  [Endpoint.AllPensionsInfoTables]: PensionInfo[];
  [Endpoint.PensionsInfoStatus]: any;
  [Endpoint.Profile]: any;
  [Endpoint.PensurePensionReturns]: any;
  [Endpoint.PensurePensionPlan]: any;
  [Endpoint.Insights]: any;
  [Endpoint.Score]: ScoreGaugeCards;
  [Endpoint.InsightsList]: ScoreGaugeCards;
  [Endpoint.Overrule]: TaskOverrule;
  [Endpoint.ListInsurances]: InsuranceType[];
  [Endpoint.ListPensionTrendsTable]: any[];
  [Endpoint.Cookies]: CookiesModel[];
  [Endpoint.SavingsGoal]: SavingsGoalModel;
  [Endpoint.Whitelabeling]: any[];
}

type QueryParams = Record<string | number, string | number | boolean>;

/**
 * Doesn't work yet. Should construct a url
 */
function constructUrl(baseEndpoint: string, queryParams?: keyedString): string {
  let url: string = baseEndpoint;
  if (queryParams) {
    const queryString = toQueryString(queryParams);
    url += `?${queryString}`;
  }

  return url;
}

type URL = Endpoint | string;
type MappedAPIOrUnknown<T> = APIResponse<
  T extends Endpoint ? APIResponseMap[T] : unknown
>;

export async function fetchData<T extends URL>(
  endpoint: T,
  query?: keyedString
): Promise<MappedAPIOrUnknown<T>> {
  try {
    return await getJSON(endpoint, query);
  } catch (error) {
    if (error.data) responseError([error?.data]);
    else if (error.errors) {
      responseError(error.errors);
    } else {
      responseError([
        {
          errorMessage: error.statusText,
          errorUserMessage: "Internal system error",
          errorType: error.status.toString(),
        },
      ]);
    }
  }
}

export async function getData(
  endpoint: string,
  query?: keyedString
): Promise<Response> {
  const key = store.getters["GET_KEY"];
  const headers = new Headers();
  headers.append("Key", key);

  const pathSep = endpoint.startsWith("/") ? "" : "/";
  const url = constructUrl(window.location.origin + pathSep + endpoint, query);

  try {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      cache: "no-cache",
      headers,
    });

    return res;
  } catch (error) {
    responseError([
      {
        errorMessage: error.statusText,
        errorUserMessage: "Internal system error",
        errorType: error.status.toString(),
      },
    ]);
  }
}
export async function getJSON<T extends URL>(
  endpoint: T,
  query?: keyedString
): Promise<MappedAPIOrUnknown<T>> {
  const res = await getData(endpoint, query);
  let json: MappedAPIOrUnknown<T>;
  if (res.status !== 200) {
    responseError([
      {
        errorMessage: res.statusText,
        errorUserMessage: "Internal system error",
        errorType: res.status.toString(),
      },
    ]);
  }
  try {
    json = await res.json();
  } catch (error) {
    console.error("Probably not json", error, res);
    return error;
  }
  if (!json.isSuccess) {
    json.errors.map((x: APIErrorType) => {
      throw new APIError(`Failed API Call: ${x.errorMessage}`, x);
    });
  }

  return json;
}

export async function postData<T extends Endpoint | string>(
  endpoint: T | string,
  data: keyedStringWithArrays | Record<any, any>
): Promise<APIResponse<T extends Endpoint ? APIResponseMap[T] : unknown>> {
  const key = store.getters["GET_KEY"];
  const res: any = await post(endpoint, data, key, undefined);
  return res;
}

/**
 * Maps a record of key/val pairs to a query string
 * e.g. {id: 1, param: "hello"} => "id=1&param=hello"
 * @param obj record of keys and values
 */
export function toQueryString(obj: keyedString): string {
  return Object.entries(obj)
    .map((x) => x.join("="))
    .join("&");
}

/**
 * Maps a query string to a record of key/val pairs
 * e.g. "id=1&param=hello" => {id: 1, param: "hello"}
 * @param queryString record of keys and values
 */
export function toRecord(queryString: string): Record<string, string> {
  return Object.fromEntries(queryString.split("&").map((x) => x.split("=")));
}

// queryStringsToMap() {
//   console.log('store', s);
//       const sub = s.substring(1, s.length - 1);
//       console.log('store', sub);
//       const x = sub.split('&').map(x => ({ key: x.split('=')[0], value: x.split('=')[1] }))[0];
//       console.log('store', x);
// }
