import type {
  APIErrorResponse,
  APIResponse,
  APISuccessResponse,
} from "@core/models/apiResponse.model";
import type { APIErrorType } from "@core/base/errors";
import { APIError } from "@core/base/errors";
import { store } from "../src/store";
import type { ComponentData } from "@core/models/store.model";
import type { Router } from "vue-router";

export function query(className: string): HTMLElement {
  return document.querySelector(className) as HTMLElement;
}
export function queryAll(className: string): NodeList {
  return document.querySelectorAll(className) as NodeList;
}
export function id(idName: string): HTMLElement {
  return document.getElementById(idName) as HTMLElement;
}

export function postFormData<T>(
  postUrl: string,
  data: Record<string, string | Blob | null>,
  noPrefix?: boolean
): Promise<APIResponse<T>> {
  const formData: FormData = new FormData();
  if (data) {
    Object.entries(data).forEach((el): void => {
      if (noPrefix) {
        //             key     value
        formData.append(el[0], el[1]);
      } else {
        formData.append(`leadValues[${el[0]}]`, el[1]);
      }
    });
  }

  store.commit("SET_FORM_DATA", { isPosting: true });

  return postData(postUrl, formData);
}

export async function postCompValues(
  dataToPost: Record<string, string>,
  router: Router
): Promise<unknown> {
  // const localData = dataToPost.map((el) => {
  //   if (el.value) {
  //     return { [el.name]: el.value };
  //   }
  // });
  const res: APIResponse = await postFormData(
    "/api/dashboard/onboarding/",
    dataToPost
  );
  if (res?.isSuccess) {
    router.push(res.name.toLowerCase());
    return res.formData;
  } else {
    responseError(res.errors);
  }
}

export function postData<T>(
  postUrl: string,
  value: FormData,
  signal?: AbortSignal
): Promise<APISuccessResponse<T>> {
  const key = store.getters.GET_KEY;
  if (typeof value === "object") {
    value.append("Key", key);
  }
  return postForm<T>(postUrl, value);
}
export function postForm<T>(
  postUrl: string,
  value: FormData,
  signal?: AbortSignal
): Promise<APISuccessResponse<T>> {
  const pathSep = postUrl.startsWith("/") ? "" : "/";
  let abortSignal: AbortSignal | null = null;
  if (AbortController && AbortSignal && signal) {
    abortSignal = signal;
  }
  return fetch(window.location.origin + pathSep + postUrl, {
    method: "POST",
    mode: "no-cors",
    credentials: "include",
    cache: "no-cache",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: value,
    signal: abortSignal,
  })
    .then(async (response) => {
      return await response.json();
    })
    .then(
      (response: APIResponse<APISuccessResponse<T> | APIErrorResponse<T>>) => {
        return handleResponse(response);
      }
    )
    .catch((error: Error): any => {
      return clientError(error);
    });
}
export function post<T, V>(
  postUrl: string,
  value: V,
  key: string
): Promise<APIResponse<T>> {
  const pathSep = postUrl.startsWith("/") ? "" : "/";
  //const errorCheck = '?fakeerrors=x';
  return fetch(window.location.origin + pathSep + postUrl, {
    method: "POST",
    credentials: "include",
    cache: "no-cache",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Key: key ?? null,
    },
    body: JSON.stringify(value),
  })
    .then((response: any) => {
      return response.json();
    })
    .then((response: any) => {
      return handleResponse<T>(response);
    })
    .catch((error: Error): any => {
      clientError(error);
    });
}

export async function getData<T>(
  getUrl: string,
  key?: string,
  signal?: AbortSignal
): Promise<APISuccessResponse<T> | void> {
  const pathSep = getUrl.startsWith("/") ? "" : "/";
  let abortSignal: AbortSignal | null = null;
  if (AbortController && AbortSignal && signal) {
    abortSignal = signal;
  }

  key = store.getters.GET_KEY;

  const headers = new Headers();
  if (key) {
    headers.append("Key", key);
  }
  return fetch(window.location.origin + pathSep + getUrl, {
    method: "GET",
    credentials: "include",
    cache: "no-cache",
    headers,
    signal: abortSignal,
  })
    .then((res) => {
      if (res.status === 500) {
        throw {
          errorMessage: res.statusText,
          errorTicket: undefined,
          errorTrace: undefined,
          errorType: res.status.toString(),
          errorUserMessage: "Intern systemfejl",
        };
      }
      return res;
    })
    .then((response) => {
      return response.json();
    })
    .then((response: APIResponse<T>) => {
      return handleResponse<T>(response);
    })
    .catch((error: Error) => {
      clientError(error);
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clientError(
  error: any
): {
  errorMessage: string;
  errorName: string;
  errorTicket: string;
}[] {
  error.errors ? (error = error.errors[0]) : error;
  const errorCustom = [
    {
      errorMessage: error.errorMessage || error.stack,
      errorUserMessage: error.errorMessage,
      errorName: error.name || error.message,
      errorTicket: error.errorTicket,
      errorType: "API ERROR",
      errorTrace: "string",
    },
  ];
  responseError(errorCustom as any);
  throw new APIError("API ERROR", errorCustom[0]);
}

export function handleResponse<T>(
  response: APIResponse<T>
): APISuccessResponse<T> | void {
  store.commit("SET_FORM_DATA", { isPosting: false });
  if (response?.isSuccess) {
    if (response.key) {
      store.commit("SET_KEY", response.key);
    }
    return response;
  } else {
    responseError(response.errors);
    return response;
  }
  return response;
}

export async function getNonJsonData<T>(
  getUrl: string
): Promise<APISuccessResponse<T> | void> {
  const pathSep = getUrl.startsWith("/") ? "" : "/";
  const options = { method: "GET", credentials: "include", cache: "no-cache" };
  const res = ((await fetch(
    window.location.origin + pathSep + getUrl,
    options as any
  )) as unknown) as APIResponse<T>;
  if (!res?.isSuccess) {
    clientError(res.errors);
  } else {
    return handleResponse(res);
  }
}

export function responseError(error: APIErrorType[]): void {
  if (!error?.length) return;
  store.commit("SET_FORM_DATA", { isPosting: false });
  store.commit("SET_FORM_DATA", { enableSubmitBtn: false });
  store.commit("SET_ERROR", error);
  store.commit("SET_MODAL_STATE", "errorModalComp");
}

export function addPageKey(): void {
  const pageName: string = store.getters.GET_PAGE_NAME;
  if (!isPageOpenedBefore() && pageName) {
    store.commit("SET_PAGE_NAME_KEY", { [pageName]: store.getters.GET_KEY });
  }
}
export function isPageOpenedBefore(): boolean {
  const result =
    store.getters.GET_PAGE_KEYS[store.getters.GET_PAGE_NAME] !== undefined
      ? true
      : false;
  return result;
}
export function getCookie(name: string): string | null {
  const patt = new RegExp("(?:^|;\\s*)" + name + "=([^;]*)");
  const match = patt.exec(document.cookie);
  return match ? match[1] : null;
}
export function setCookie(name: string, value: string, days: number): void {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
export function addHubSpotChat(): void {
  const parent = document.head;
  const createScriptTag: HTMLScriptElement = document.createElement("script");
  createScriptTag.type = "text/javascript";
  createScriptTag.id = "hs-script-loader";
  createScriptTag.async = true;
  createScriptTag.defer = true;
  createScriptTag.src = "//js.hs-scripts.com/5888429.js";
  parent.appendChild(createScriptTag);
}
export function addHubspotBooking(): void {
  const parent = document.head;
  const createScriptTag: HTMLScriptElement = document.createElement("script");
  createScriptTag.async = true;
  createScriptTag.defer = true;
  createScriptTag.type = "text/javascript";
  createScriptTag.src =
    "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
  parent.appendChild(createScriptTag);
}

//todo check inner workings
export function setsliderval(
  keyName: string,
  val: number,
  postData?: Record<string, unknown>
): typeof postData {
  postData = {};
  return (postData = Object.assign({}, postData, {
    [keyName]: val.toString(),
  }));
}

export function validateSingleInput(elData: ComponentData): boolean {
  const validation = elData.validationType;
  const hasEmailCheckBox = store.getters.GET_CONTACT_BY_EMAIL;
  const val = elData.value;
  const emailIsvalid = regexTestEmail(val);
  let result = false;
  switch (validation) {
    case "name":
      result = regexTestName(val);
      break;
    case "income":
      result = val.length > 2;
      break;
    case "email":
      if (hasEmailCheckBox) {
        const emailCheckBoxIsChecked =
          store.getters.GET_CONTACT_BY_EMAIL_IS_CHECKED;
        if (emailCheckBoxIsChecked && emailIsvalid) {
          result = true;
        } else {
          result = false;
        }
      } else {
        result = emailIsvalid;
      }
      break;
    case "cpr":
      result = regexTestCpr(val);
      break;
    default:
      result = true;
      break;
  }
  return result;
}
export function validateMultipleInput(elData: ComponentData[]): boolean {
  const resultArray: boolean[] = [];
  elData.forEach((el: ComponentData) => {
    const val: string = el.value;
    switch (el.validationType) {
      case "name":
        resultArray.push(regexTestName(val));
        break;
      case "email":
        resultArray.push(regexTestEmail(val));
        break;
      case "cpr":
        resultArray.push(regexTestCpr(val));
        break;
      default:
        resultArray.push(true);
        break;
    }
  });
  // if all inputs pass
  return !resultArray.includes(false);
}
export function regexTestEmail(val: string): boolean {
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
  return emailRegex.test(val);
}
export function regexTestName(val: string): boolean {
  return val.length > 2;
}
export function regexTestCpr(val: string): boolean {
  const cprRegex = /^\d{10}$/g;
  return cprRegex.test(val);
}

export function setcardval(
  val: boolean,
  index: number,
  data: CardData[]
): void {
  data.forEach((el: CardData): void => {
    el.checked = false;
  });
  data[0].checked = val;
  store.commit("SET_FORM_DATA", { cardIsSelected: true });
}

export function isCardSelected(cardArray: CardData[]): void {
  cardArray.forEach((el: CardData): void => {
    if (el.checked) {
      store.commit("SET_FORM_DATA", { cardIsSelected: true });
    }
  });
}

export function tryGetImage(providerNumber: number): string | undefined {
  return `/image2/pension-companies/${providerNumber}.svg`;
}

export function fetchImg(
  url: string
): Promise<string | undefined> | string | undefined {
  if (url.endsWith(".png") || url.endsWith(".jpg")) {
    return `<img class="w-full h-full" src="${url}" />`;
  } else if (url.endsWith(".svg")) {
    return fetch(window.location.origin + url).then((a) => a.text());
  }
}

export function addHubSpotChatAndBook(): void {
  const parent = document.head;
  const createScriptTag: HTMLScriptElement = document.createElement("script"),
    createScriptTag2: HTMLScriptElement = document.createElement("script");
  createScriptTag.type = "text/javascript";
  createScriptTag.id = "hs-script-loader";
  createScriptTag.async = true;
  createScriptTag.defer = true;
  createScriptTag.src = "//js.hs-scripts.com/5888429.js";
  createScriptTag2.type = "text/javascript";
  createScriptTag2.src =
    "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
  parent.appendChild(createScriptTag);
  parent.appendChild(createScriptTag2);
}
