<template>
  <div id="welcomeHeader" class="rounded-2xl flex flex-col light">
    <div
      class="ml-2 p-5 flex flex-col lg:flex-row justify-between items-center"
    >
      <FadeTransition>
        <div
          v-if="header && subHeader"
          :key="header + subHeader"
          class="text-center lg:text-left"
          :class="[button ? 'max-w-3xl' : '']"
        >
          <h2 class="text-4xl text-white mb-5">
            {{ header }}
          </h2>
          <p class="text-lg text-white font-title1">{{ subHeader }}</p>
        </div>
      </FadeTransition>

      <div
        v-if="button"
        id="buttonCustom"
        class="text-center flex justify-center items-center"
      >
        <button
          class="white-shadow text-primary px-8 py-1 lg:py-3 my-2 md:my-0 lg:mr-12 bg-white rounded-md"
          style="font-size: 1.375rem"
          @click="startTour()"
        >
          {{ button }}
        </button>
      </div>
    </div>
    <div v-if="showSubroutes" class="flex ml-6 slideBounceInContainer">
      <template v-for="(tab, i) in childrenRoutes" :key="tab.name">
        <router-link
          v-if="showTab(tab, i)"
          :to="{ path: tab.path }"
          class="mr-4 text-lg flex subMenu slideBounceIn opacity-0"
        >
          <span class="w-5 grid grid-cols-1 grid-rows-2">
            <span class="darkBg"></span>
            <span class="rounded-br-xl darkBg"></span>
          </span>
          <span class="rounded-t-xl px-3 py-1.5 lg:py-1 text-lg lg:text-base">
            {{ tab.meta?.titleName }}
          </span>
          <span class="w-5 grid grid-cols-1 grid-rows-2">
            <span class="darkBg"></span>
            <span class="rounded-bl-xl darkBg"></span>
          </span>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue';
import { defineProps, computed } from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const props = defineProps<{
  header: string;
  subHeader: string;
  button?: string;
}>();

const router = useRouter();
const route = useRoute();
const store = useStore();

const subroutesVisibilityFromConfig = computed(
  () => store.getters.GET_UI_STATE.subrouteTabsVisibility
);

const mainRouteName = computed(() => {
  return route.fullPath?.match(/\/*\w+/gi)[0].replace('\/', '');
});

const childrenRoutes: ComputedRef<null | RouteRecordRaw[]> = computed(() => {
  const routes = router.options.routes;
  const parentPath = route.matched[1].path;
  const root = router.options?.base;
  const parent = parentPath.replace('/', '');
  let children = null;
  if (parent === root) {
    return null;
  } else {
    let i = 0;
    for (i; i < routes.length - 1; i++) {
      if (routes[i].children && !children) {
        routes[i].children?.find((component) => {
          if (component.path === parentPath) {
            children = component.children;
          }
        });
      } else if (children) {
        break;
      }
    }
  }

  return children;
});

const hasMultipleSubroutes = computed(() => {
  if (!subroutesVisibilityFromConfig.value) return false;
  return childrenRoutes.value?.some(
    (el) => subroutesVisibilityFromConfig.value[el.name] === true
  );
});

const showSubroutes = computed(() => {
  if (mainRouteName.value === 'options') return true;

  return hasMultipleSubroutes.value;
});

function showTab(tab: RouteRecordRaw, i: number) {
  if (!tab.name) return;
  if (mainRouteName.value === 'options' || i === 0) return true;
  else {
    return subroutesVisibilityFromConfig.value[tab.name];
  }
}
</script>

<style lang="scss" scoped>
$bgColor: rgb(253, 248, 243);
$dark: var(--side-bar-color);

#welcomeHeader {
  background: linear-gradient(153.62deg, $dark 32.7%, #4b4b4b 66.63%);
}

.darkBg {
  background-color: $dark !important;
}

.subMenu {
  &.router-link-exact-active span {
    &:not(.corners) {
      color: var(--color-primary);
      background-color: var(--dashboard-background);
    }
    & svg path {
      fill: var(--dashboard-background);
    }
  }
  color: rgb(255, 255, 255);
  & svg path {
    fill: none;
  }
}
</style>
