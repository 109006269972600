import lang from '../assets/locale/da_PENSURE.json';
import { keyedStringWithArrays } from '@core/base/helperTypes';

export class LanguageRepository {
  text: keyedStringWithArrays;
  constructor(locale = 'da') {
    this.text = lang;
  }

  getTranslations(locale: string): keyedStringWithArrays {
    return this.text;
  }
}
