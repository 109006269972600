import type {
  UserAuthModel,
  UserInfoModel,
  PreferencesModel,
  UserRetirementPreferences,
} from '@core/models/user.model';
import type { APIResponse } from '@core/models/apiResponse.model';

export abstract class UserRepository {
  abstract getUser(userKey: string): Promise<UserInfoModel>;
  abstract getPreferences(userKey: string): Promise<PreferencesModel>;
  abstract updatePreferences(
    userKey: string,
    preferences: UserRetirementPreferences
  ): Promise<APIResponse<unknown>>;
  abstract getAuthState(): Promise<UserAuthModel>;
  abstract logout(): Promise<void>;
  abstract getProfile(): Promise<any>;
}
