/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { RouteRecordRaw } from "vue-router";
import OnboardPage from "../src/views/OnboardPage.vue";
//import * as VueRouter from '../node_modules/vue-router/dist/vue-router';

export enum Routes {
  Nemidlogin = "/login",
  Onboarding = "/onboarding",
  Retired = "/onboarding/retired",
  Retirement = "/onboarding/retirement",
  Signup = "/onboarding/signup",
  Income = "/onboarding/income",
  Details = "/onboarding/details",
  Dreams = "/onboarding/dreams",
  PensionAge = "/onboarding/pensionAge",
  PensionsInfo = "/onboarding/loginPensionsInfo",
  Login = "/onboarding/login",
  Overview = "/overview",
  Savings = "/savings",
  Payouts = "/payouts",
  Pensions = "/payouts/pensions",
  Insurances = "/insurances",
  InsurancesOverview = "/insurances/overview",
  InsurancesUse = "/insurances/use",
  Health = "/health",
  Planning = "/planning",
  Advisory = "/advisory",
  Insights = "/advisory/insights",
  Meetings = "/advisory/meetings",
  Inbox = "/inbox",
  Options = "/options",
  Profile = "/options/profile",
  Preferences = "/options/preferences",
  Error = "/error",
  FourOFour = "/404",
  ManagePremium = "/options/premium",
}

export const isOnboardingPath = (route: string): boolean =>
  route.startsWith("/onboarding");

const onboardingRoutes = [
  {
    path: Routes.Onboarding,
    name: "OnboardPage",
    component: OnboardPage,
    children: [
      {
        path: Routes.Retirement,
        name: "retirement",
        component: () => import("./views/Retirement.vue"),
      },
      {
        path: Routes.Retired,
        name: "retired",
        component: () => import("./views/Retired.vue"),
      },
      {
        path: Routes.Signup,
        name: "signup",
        component: () => import("./views/SignUp.vue"),
        meta: {
          title: " - Opret en bruger",
        },
      },
      {
        path: Routes.Income,
        name: "income",
        component: () => import("./views/Income.vue"),
      },
      {
        path: Routes.Details,
        name: "details",
        component: () => import("./views/Details.vue"),
      },
      {
        path: Routes.Dreams,
        name: "dreams",
        component: () => import("./views/Dreams.vue"),
      },
      {
        path: Routes.PensionAge,
        name: "pensionage",
        component: () => import("./views/PensionAge.vue"),
      },
      {
        path: Routes.Login,
        name: "login",
        component: () => import("./views/Login.vue"),
        meta: {
          title: "Log ind",
        },
        metaTags: [
          {
            property: "title",
          },
          {
            property: "description",
            content:
              "Log ind på Mit Pensure med en allerede eksisterende bruger. Hvis du ikke allerede har en bruger, så opret en bruger først.",
          },
        ],
      },
      {
        path: Routes.PensionsInfo,
        name: "loginpensionsinfo",
        component: () => import("./views/PensionsInfo.vue"),
      },
    ],
  },
];

const dashboardRoutes = [
  {
    path: "",

    components: {
      default: () => import("./views/Dashboard.vue"),
      navMenu: () =>
        import("./components/vertical-nav-menu/VerticalNavMenu.vue"),
    },
    meta: {
      title: "",
    },
    children: [
      {
        path: Routes.Overview,
        name: "overview",
        component: () => import("./views/Overview.vue"),
        meta: {
          title: " - Overblik",
        },
      },
      {
        path: Routes.Savings,
        name: "savings",
        component: () => import("./views/Savings.vue"),
        meta: {
          title: " - Opsparing",
        },
      },
      {
        path: Routes.Payouts,
        redirect: Routes.Pensions,
        component: () => import("./views/Payouts.vue"),
        children: [
          {
            path: Routes.Pensions,
            name: "pensions",
            component: () => import("./views/Pensions.vue"),
            meta: {
              title: " - Udbetalinger",
              titleName: "Pension",
            },
          },
        ],
      },
      {
        path: Routes.Insurances,
        name: "insurances",
        redirect: Routes.InsurancesOverview,
        component: () => import("./views/Insurances.vue"),
        meta: {
          title: " - Forsikringer",
          titleName: "Forsikring",
        },
        children: [
          {
            path: Routes.InsurancesOverview,
            name: "insurancesOverview",
            component: () => import("./views/InsurancesOverview.vue"),
            meta: {
              title: " - Forsikringer",
              titleName: "Overblik",
            },
          },
          {
            path: Routes.InsurancesUse,
            name: "insurancesUse",
            component: () => import("./views/InsurancesUse.vue"),
            meta: {
              title: " - Forsikringer",
              titleName: "Brug forsikring",
            },
          },
        ],
      },
      {
        path: Routes.Health,
        name: "health",
        component: () => import("./views/Health.vue"),
        meta: {
          title: " - Sundhed",
          titleName: "Forsikring",
        },
      },
      {
        path: Routes.Planning,
        name: "planning",
        component: () => import("./views/Planning.vue"),
        meta: {
          title: " - Beregnere",
        },
      },
      {
        path: Routes.Advisory,
        redirect: Routes.Insights,
        component: () => import("./views/Advisory.vue"),
        children: [
          {
            path: Routes.Insights,
            name: "insights",
            component: () => import("./views/Insights.vue"),
            meta: {
              title: " - Indsigt",
              titleName: "Indsigt",
            },
          },
          {
            path: Routes.Meetings,
            name: "meetings",
            component: () => import("./views/Meetings.vue"),
            meta: {
              title: " - Møder",
              titleName: "Møder",
            },
          },
        ],
      },
      {
        path: Routes.Inbox,
        name: "inbox",
        component: () => import("./views/Inbox.vue"),
        meta: {
          title: " - Beskeder",
        },
      },
      {
        path: Routes.Options,
        name: "options",
        redirect: Routes.Preferences,
        component: () => import("./views/Options.vue"),
        meta: {
          title: " - Profil",
        },
        children: [
          {
            path: Routes.Profile,
            name: "profile",
            meta: {
              titleName: "Profil",
            },

            component: () => import("./views/Profile.vue"),
          },
          {
            path: Routes.Preferences,
            name: "preferences",
            meta: {
              title: " - Præferencer",
              titleName: "Præferencer",
            },
            component: () => import("./views/Preferences.vue"),
          },
        ],
      },
    ],
  },
];
export const routes = [
  {
    path: Routes.Nemidlogin,
    name: "nemidlogin",
    component: () => import("./views/Login.vue"),
  },
  ...onboardingRoutes,
  ...dashboardRoutes,
  {
    path: Routes.Error,
    name: "errorPage",
    component: () => import("./views/ErrorPage.vue"),
  },
  {
    path: Routes.FourOFour,
    name: "404",
    component: () => import("./views/404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
  },
] as RouteRecordRaw[];
