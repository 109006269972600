<template>
  <template v-if="loading">
    <div class="loading-wrapper">
      <h2>Loading...</h2>
    </div>
  </template>
  <template v-else>
    <router-view />

    <FadeTransition :duration="150">
      <modal
        v-if="modalId"
        id="modalOffset"
        :duration="100"
        :close-btn-is-visible="true"
        :close-on-click-outside="true"
        :hide-function="() => store.dispatch('hideModal')"
      >
        <slot>
          <div class="customWidthModal flex flex-col" :style="setModalStyles">
            <component :is="modalId" />
          </div>
        </slot>
      </modal>
    </FadeTransition>
  </template>
</template>

<script lang="ts">
export default defineComponent({
  components: {
    errorModalComp: defineAsyncComponent(
      () => import('../components/modals/ErrorModal.vue')
    ),
    privacyPolicy: defineAsyncComponent(
      () => import('../components/modals/PrivacyPolicyModal.vue')
    ),
    userCondiitons: defineAsyncComponent(
      () => import('../components/modals/UserConditionsModal.vue')
    ),
    premiumNotLaunchedModal: defineAsyncComponent(
      () => import('../components/modals/PremiumIsNotLaunchedModal.vue')
    ),
  },
});
</script>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { environment } from './configuration/environment';
import type { Ref } from 'vue';
import {
  defineComponent,
  computed,
  ref,
  onMounted,
  defineAsyncComponent,
  nextTick,
  watch,
} from 'vue';
import { getWhiteLabelConfig, setTheme } from './core/whiteLabeling.service';
import { useRoute, useRouter } from 'vue-router';
import type { Whitelabel } from '@adapters/white-label-web.repository';
import da_PHCS from '../src/assets/locale/da_PHCS.json';
import { injectStrictI18n } from './plugins/i18n';

// mixins: [myMixin],

// const analytics= inject('analytics');
//const errorLogger = inject('errorLogger');

const route = useRoute();
const store = useStore();
const router = useRouter();
const modalId = computed(() => store.getters.GET_MODAL_STATE);

const loading: Ref<boolean> = ref(true);

const env = import.meta.env.MODE;

// async function initAnalyticsReporting({
//   key,
//   url,
// }: {
//   key: string;
//   url: string;
// }): Promise<void> {
//   const name = isAdminSignIn();
//   if (name) {
//     console.log('[ANALYTICS] Do not track impersonators');
//     return;
//   }
//   // return new Promise((resolve, reject) => {
//   //   analytics.init(key, {
//   //     api_host: url,
//   //     loaded: () => resolve(),
//   //   } as any);
//   // });
// }

async function initEnvironment(env: string): Promise<void> {
  if (env === 'production') {
    const conf = environment.prod;
    initErrorReporting(conf.errorReporting, env);
    // await initAnalyticsReporting(conf.analytics);
  } else if (env !== 'production') {
    // Right now a catch-all for non-production builds
    //const conf = environment.staging;
    //initErrorReporting(conf.errorReporting, env);
    // await initAnalyticsReporting(conf.analytics);
  } else {
    console.error('[ANALYTICS] Environment unknown', env);
  }
}

function initErrorReporting(
  { key, sentryDSN, overrideConsole },
  env: string
): void {
  // errorLogger.init({
  //   id: key,
  //   release: `pensionsguiden@${myMixin.data.appVersion}`,
  //   dsn: sentryDSN,
  //   overrideConsole,
  //   environment: env,
  // });
}

const setModalStyles = computed(() => {
  const hasWidth = store.getters.GET_MODAL_WIDTH;
  return hasWidth ? `width: ${hasWidth}` : 'width: 56rem';
});

function isDevtoolsOpen(): void {
  let webToolsOpened = false;
  if (webToolsOpened) {
    return;
  }
  if (
    window.outerWidth - window.innerWidth > 160 ||
    window.outerHeight - window.innerHeight > 160
  ) {
    webToolsOpened = true;
    console.log(`
          Hey there, human :)
          You’re an inquisitive one!
          We like that → future.pensure.dk
        `);
  }
}

//injectGoogleTagManager(window, document, 'script', 'dataLayer', 'GTM-KZVQQB6');
initEnvironment(env);
//window.addEventListener('CookiebotOnLoad', setDoNotTrackHubspot);
const i18n = injectStrictI18n('i18n');
function setUpLocale(config: Whitelabel) {
  if (config.metaData.companyName.toLowerCase() !== 'pensure') {
    i18n.text = da_PHCS;
  }
}

async function getWhiteLabel() {
  const config = await getWhiteLabelConfig();
  if (config) {
    setTheme(config.styles);
    delete config.styles;
    store.commit('SET_WHITELABEL', config);
    setUpLocale(config);
    nextTick(() => (loading.value = false));
  }
}

onMounted(() => {
  getWhiteLabel();
  // Test whether developer tools is open
  isDevtoolsOpen();
  //window.addEventListener('resize', isDevtoolsOpen);
});

const companyName = computed(
  () => store.getters.GET_WHITELABEL?.metaData.companyName
);

function setPageTitle() {
  const name = route.meta.title;
  if (!companyName.value || !name) {
    window.document.title = 'pensionsGuiden';
    return;
  }
  window.document.title = companyName.value + name;
}

watch(
  () => router.currentRoute.value,
  () => setPageTitle()
);

// prevent scroll if modal is shown
watch(
  () => modalId.value,
  (newVal) => {
    newVal
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden');
  }
);

router.isReady().then(() => setPageTitle());
</script>

<style lang="scss">
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
}

@font-face {
  font-family: Pensure;
  src: url('/fonts/CoconOT-Light.otf') format('OpenType');
  font-weight: normal;
}

@font-face {
  font-family: Merriweather;
  src: url('/fonts/Merriweather-Light.ttf') format('truetype'),
    url('/fonts/Merriweather-Regular.ttf') format('truetype'),
    url('/fonts/Merriweather-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  src: url('/fonts/Lato-Regular.ttf') format('truetype'),
    url('/fonts/Lato-Light.ttf') format('truetype'),
    url('/fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: EXO2;
  src: url('/fonts/Exo2-ExtraBold.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: EXO2;
  src: url('/fonts/Exo2-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: EXO;
  src: url('/fonts/Exo-Light.ttf');
  font-weight: light;
}
@font-face {
  font-family: EXO;
  src: url('/fonts/Exo-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: EXO;
  src: url('/fonts/Exo-Bold.ttf') format('truetype');
  font-weight: bold;
}

body,
html {
  height: 100%;
}

body,
html,
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$mq-sm: 576px;
$mq-md: 768px;
$mq-lg: 992px;
$mq-xl: 1300px;

@media (min-width: $mq-sm) and (max-width: $mq-lg) {
  html {
    font-size: 12px;
  }
}

@media (min-width: $mq-lg) and (max-width: $mq-xl) {
  html {
    font-size: 13px;
  }
}

h1.dynamicHeaders {
  font-family: var(--font-title-1), var(--font-title);
  word-break: break-word;
  text-transform: var(--text-uppercase);
  line-height: var(--title-line-height) !important;
  &:not(.text-white):not(.text-dark) {
    color: var(--custom-text-color);
  }
}
h2,
h3,
h4,
h5,
h6 {
  & .dynamicHeaders:not(.text-primary) {
    color: var(--text-color-dark);
    font-family: var(--font-title);
    text-transform: var(--text-uppercase);
    line-height: var(--title-line-height);
    word-break: break-word;
  }
}

div,
p,
ul,
li,
a,
span {
  font-family: var(--font-body);
}

body,
main .modal-wraper .modal {
  div,
  span,
  ul,
  p,
  li,
  a {
    line-height: var(--body-line-height);
  }
}

ul,
ol {
  &.list-disc {
    list-style: disc;
  }

  &.list-square {
    list-style: square;
  }
}

.modal-wraper {
  @media (max-width: 699px) {
    height: calc(100% - 68px);
  }
}

[data-test='next-button'] {
  &:not(.modalBtn) {
    padding: 0.7rem 0.5rem;
    font-size: 1.2rem;
  }
  &.modalBtn {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    &:disabled {
      filter: saturate(0);
      opacity: 0.7;
      background-color: var(--bg-color-dark) !important;

      pointer-events: none !important;
      cursor: not-allowed;
    }
  }
  &:not(:disabled) {
    background: var(--button-background);
  }
  border-width: 0px !important;
}

.slide {
  &-right,
  &-left,
  &-down,
  &-down-down {
    &-enter-active {
      opacity: 1;
      transition: all 0.3s ease-out;
    }
    &-leave {
      &-active {
        opacity: 1;
        transition: all 0.2s ease-in;
      }
      &-to {
        opacity: 0;
      }
    }
  }
  &-right,
  &-left {
    &-enter-from {
      opacity: 1;
    }
    &-leave-active {
      transform: translateX(0%);
      opacity: 0;
    }
  }

  &-down,
  &-down-down {
    &-enter {
      &-from {
        opacity: 0;
      }
      &-active {
        transform: translateY(0%);
      }
    }
    &-leave-active {
      transform: translateY(0%);
    }
  }

  &-right {
    &-enter {
      &-active {
        transform: translateX(0%);
      }
      &-from {
        transform: translateX(50%);
      }
    }
    &-leave-to {
      transform: translateX(-50%);
    }
  }
  &-left {
    &-enter {
      &-active {
        transform: translateX(0%);
      }
      &-from {
        transform: translateX(-100%);
        opacity: 0;
      }
    }
    &-leave-to {
      transform: translateX(50%);
    }
  }
  &-down {
    &-enter-from {
      transform: translateY(50%);
    }
    &-leave-to {
      transform: translateY(-50%);
    }
  }
  &-down-down {
    &-enter-from,
    &-leave-to {
      transform: translateY(100%);
    }
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear {
  animation: appear 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@keyframes slideBounceIn {
  0% {
    filter: blur(5px);
    opacity: 0;
    transform: translateY(20%) scaleY(0.5);
  }
  80% {
    filter: blur(3px);
    opacity: 0.3;
    transform: scaleY(1.3) scaleX(1.3);
  }
  90% {
    opacity: 0.7;
    transform: scaleY(0.9);
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}

.slideBounceIn {
  opacity: 0;
  animation: slideBounceIn 0.35s ease-in-out forwards;
  transform-origin: center bottom;
}

// couldnt figure how to offset 1.2s + offset with @for@for
.slideBounceInContainer {
  .slideBounceIn:nth-child(1) {
    animation-delay: 1.2s;
  }
  .slideBounceIn:nth-child(2) {
    animation-delay: 1.4s;
  }
  .slideBounceIn:nth-child(3) {
    animation-delay: 1.6s;
  }
  .slideBounceIn:nth-child(4) {
    animation-delay: 1.8s;
  }
  .slideBounceIn:nth-child(5) {
    animation-delay: 2s;
  }
  .slideBounceIn:nth-child(6) {
    animation-delay: 2.2s;
  }
  .slideBounceIn:nth-child(7) {
    animation-delay: 2.4s;
  }
  .slideBounceIn:nth-child(8) {
    animation-delay: 2.6s;
  }
}

@keyframes staggerList {
  from {
    filter: blur(3);
    opacity: 0;
    transform: translateX(10%);
  }
  to {
    filter: blur(0);
    opacity: 1;
  }
}

.staggerList {
  &-enter-active {
    transition: all 0.3s ease-in-out;

    opacity: 0;
    filter: blur(1px);
    transform: translateX(10%);
  }
  &-enter-to {
    transform: translateX(0);
    filter: blur(0);
    opacity: 1;
  }
}

.chevron {
  $size: 0.7em;
  min-width: 5em;
  text-align: center;
  &:after {
    transition: transform 0.3s ease-in-out !important;
    content: '';
    transform: rotate(0deg);
    border-style: solid;
    border-width: $size / 3 $size / 3 0 0;
    border-radius: 3px;
    display: inline-flex;
    height: $size + $size / 5;
    left: $size / 3;
    position: relative;
    top: $size / 3;
    transform: rotate(-45deg);
    vertical-align: top;
    width: $size + $size / 5;
    margin-left: 0.5em;
  }
  &.down {
    &:after {
      transform: rotate(132deg);
      margin-top: -4%;
    }
  }
  &.right {
    &:after {
      transform: rotate(41deg);
      margin-top: -2%;
    }
  }
  &.left {
    &:after {
      transform: rotate(-139deg);
      margin-top: -2%;
    }
  }
}
</style>
