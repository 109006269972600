import { UserWebRepository } from '@adapters/user-web.repository';
import type {
  UserInfoModel,
  PreferencesModel,
  UserRetirementPreferences,
} from '@core/models/user.model';

import { APIError } from './base/errors';
import type {
  APIErrorResponse,
  APISuccessResponse,
} from './models/apiResponse.model';
import { store } from '../store';
import { responseError } from '@shared/Methods';
// import { of } from './base/helpers';

// TODO: Refactor to be injected into class so the service doesn't depend on the adapter
const repo = new UserWebRepository();

export class UserService {
  async logout(): Promise<void> {
    const res = await this.repo.logout();
    return res;
  }
  // isSignedIn = false;
  repo = repo;
  async getUser(): Promise<UserInfoModel> {
    const res = await this.repo.getUser();
    res.email;
    return res;
  }
  async getPreferences(): Promise<PreferencesModel> {
    try {
      return await repo.getPreferences();
    } catch (error) {
      console.error('Uh, oh', error);
      throw new APIError('Get Preferences', error);
    }
  }
  async updatePreferences(
    preferences: UserRetirementPreferences
  ): Promise<unknown> {
    try {
      return await repo.updatePreferences(preferences);
    } catch (error) {
      console.error('Uh, oh', error);
      throw new APIError('Update Preferences', error);
    }
  }

  async getAuthState(): Promise<boolean> {
    const dashboardKey = store.getters.GET_KEY;
    const userAuthData = await repo.getAuthState();
    if (!dashboardKey || !userAuthData) return false;

    if (userAuthData?.key) store.commit('SET_KEY', userAuthData.key);
    return userAuthData.isAuthenticated;
  }

  async deleteAccount(
    userName: string
  ): Promise<APISuccessResponse | APIErrorResponse> {
    try {
      return await repo.deleteUser(userName);
    } catch (error) {
      responseError(error);
    }
  }

  getProfile(): Promise<any> {
    return repo.getProfile();
  }
  updateProfile(data: any): Promise<any> {
    return repo.updateProfile(data);
  }
}
export const userService = new UserService();
