import { getStyles, globalStyles } from '@adapters/white-label-web.repository';

export function setTheme(styles: globalStyles): void {
  const root = document.documentElement;
  for (const key in styles) {
    root.style.setProperty(`--${key}`, styles[key]);
  }
}

export async function getWhiteLabelConfig() {
  return await getStyles();
}
