import "./assets/tailwind.css";
import App from "./App.vue";
import i18n from "./plugins/i18n";
import { store } from "./store";
import GlanceCard from "./components/WhiteGlanceCard.vue";
import WelcomeHeader from "./components/welcome-header/WelcomeHeader.vue";
import CardOutline from "./components/CardOutline.vue";
import SystemErrorElement from "../components/misc/SystemErrorElement.vue";
import { createApp } from "vue";

import myRouter from "./router";
import {
  Modal,
  FadeTransition,
  NextButton,
  LoadingSpinner,
} from "@pensure/ui-components";
import "../node_modules/@pensure/ui-components/dist/style.css";

(async () => {
  if ("ResizeObserver" in window === false) {
    // Loads polyfill asynchronously, only if required.
    const module = await import("@juggle/resize-observer");
    window.ResizeObserver = module.ResizeObserver;
  }
})();

const app = createApp(App);

app.use(store);
app.use(myRouter);
app.use(i18n);

app.component("LoadingSpinner", LoadingSpinner);
app.component("GlanceCard", GlanceCard);
app.component("WelcomeHeader", WelcomeHeader);
app.component("PenSystemError", SystemErrorElement);
app.component("CardOutline", CardOutline);

app.component("Modal", Modal);
app.component("FadeTransition", FadeTransition);
app.component("NextButton", NextButton);

app.mixin({
  data: function () {
    return {
      appVersion: "~version~",
    };
  },
});

app.mount("#app");

const env = import.meta.env.MODE;

app.config.errorHandler = function (err, vm) {
  // if (env !== 'production') {
  //   console.error('[ERROR HANDLER]', err);
  // }
  // if (err instanceof AuthorizationError) {
  //   vm.$router.push('/login');
  // }
  // let msg = {};
  // if (err instanceof APIError) {
  //   msg = err.getData();
  // } else {
  //   msg = {
  //     errorName: err?.name,
  //     errorMessage: err?.message,
  //   };
  // }
  // // logger.error(err, msg);
  // // errorLogger.error(app, err);
  // console.error({ ...msg });
  // store.commit('SET_ERROR', [{ ...msg }]);
  // store.commit('SET_MODAL_STATE', 'errorModalComp');
};

// import { Tooltip } from '../Directives/TooltipDirective';
// Vue.directive('tooltip', Tooltip);

// export default {
//   directives,
// };
