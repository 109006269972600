export interface APIErrorType {
  errorType: string;
  errorUserMessage: string;
  errorMessage: string;
  errorTrace?: string;
  errorTicket?: string;
  name?: string;
}
export class APIError extends Error {
  data: APIErrorType;
  constructor(message: string, data: APIErrorType) {
    super(message);
    this.data = data;
    Object.setPrototypeOf(this, APIError.prototype);
  }

  getData(): APIErrorType {
    return this.data;
  }
}

export class AuthorizationError extends Error {
  data: Record<string, unknown>;
  constructor(message: string, data?: Record<string, unknown>) {
    super(message);
    this.data = data;
    Object.setPrototypeOf(this, AuthorizationError.prototype);
  }
}
