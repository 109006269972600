<template>
  <div v-if="page.fillImg" :key="page.header" class="mx-auto my-5 md:my-10 relative" style="max-width: 116px">
    <img id="greyImg" :src="page.baseImg" class="flex" rel="preload" :key="page.baseImg" />
    <img
      :src="page.fillImg"
      :class="formIsFilled"
      :key="page.fillImg"
      id="brandImg"
      rel="preload"
      class="absolute flex inset-0"
    />
  </div>
</template>

<script lang="ts" setup>
  import { defineProps, computed } from 'vue';
  const props = defineProps({
    page: {
      type: Object,
      required: true,
    },
  });
  const formIsFilled = computed(() => (props.page.enableSubmitBtn ? 'activeBrandSvg' : ''));
</script>

<style lang="scss" scoped>
  .activeBrandSvg {
    clip-path: inset(0 0 0 0) !important;
  }
  #brandImg {
    transition: clip-path 0.4s cubic-bezier(0.48, 0.02, 0.52, 0.96), filter 0.3s ease;
    clip-path: inset(100% 0 0 0);
    z-index: 2;
  }
</style>
