<template>
  <div v-if="flowIsFetched" ref="mainEl" class="flex customH relative">
    <onboardMenu class="z-10" />
    <FadeTransition>
      <div
        id="onboardingContainer"
        class="pt-10 md:pt-16 lg:pt-28 w-full flex flex-col relative px-2"
      >
        <backBtn
          v-if="page.showBackBtn"
          class="hidden lg:flex lg:flex left-6 top-14 z-10"
        />
        <div class="flex flex-col text-center justify-center relative">
          <h1
            v-if="page.header"
            class="dynamicHeaders font-bold text-bodyDark font-title1 text-2xl md:text-4xl lg:text-5xl mt-0 pt-0 pb-2 px:3 md:px-6 lg:max-w-7xl mx-auto"
            v-html="page.header"
          />
          <h2
            v-if="page.smallerHeader"
            class="mt-3 mb-5 font-title md:text-xl lg:text-base"
          >
            {{ page.smallerHeader }}
          </h2>
        </div>

        <ImgFillUp :page="page" />

        <div
          :id="$route.name?.toString()"
          :class="{ 'mb-10': $route.name !== 'login' }"
          class="my-5 lg:my-8 mx-auto px-3 w-full"
        >
          <router-view v-slot="{ Component }">
            <transition
              :name="direction === 'forward' ? 'slide-right' : 'slide-left'"
              mode="out-in"
              @before-enter="overflowH"
              @enter="overflowH"
              @after-enter="overflowN"
              @leave="overflowH"
            >
              <component
                :is="Component"
                id="transtiionContainer"
                class="comp-transition"
                @route-update="handleUpdate"
              />
            </transition>
          </router-view>
        </div>

        <progressBar
          v-if="page?.showProgressBar"
          :steps="page.steps"
          :active-circle="page.activeStep"
          :routes="routes"
        />
      </div>
    </FadeTransition>
  </div>
</template>

<script lang="ts">
import backBtn from '../../components/buttons/BackBtn.vue';
import progressBar from '../components/ProgressBarElement.vue';
import onboardMenu from '../../components/misc/OnboardMenu.vue';
import ImgFillUp from '../components/OnboardImgFillUp.vue';
import { Endpoint } from '@adapters/apiHelper';
import { defineComponent } from 'vue';
import { postData } from '@adapters/apiHelper';
export default defineComponent({
  components: {
    onboardMenu,
    ImgFillUp,
    progressBar,
    backBtn,
  },
  inheritAttrs: false,
});
</script>

<script lang="ts" setup>
import { getData, responseError } from '../../custom/Methods';
import { useStore } from 'vuex';
//import { myMixin } from '../someMixin';
// import { getBrowser, Browser } from '../core/base/helpers';
import { onBeforeRouteUpdate, useRoute } from 'vue-router';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  provide,
  reactive,
  ref,
} from 'vue';

export interface Subroute {
  header: string | null;
  smallerHeader: string | null;
  fillImg: string | null;
  baseImg: string | null;
  showProgressBar: boolean;
  showBackBtn: boolean;
  enableSubmitBtn: boolean;
}

const store = useStore();
provide('imageLinks', store.getters.GET_WHITELABEL.iconsAndImages.onboarding);

reload();

let page = reactive({
  header: null,
  smallerHeader: null,
  fillImg: null,
  baseImg: null,
  showProgressBar: true,
  showBackBtn: false,
  enableSubmitBtn: false,
});

const routes = [
  'retirement',
  'signup',
  'income',
  'details',
  'dreams',
  'pensionAge',
  'login',
];

function handleUpdate(data: Subroute): void {
  Object.assign(page, data);
}

function resetScroll() {
  if (window.innerWidth <= 699 && window.scrollY > 0) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}

function resetState(): void {
  for (const index in page) {
    if (index === 'showProgressBar') return;
    if (typeof page[index] === 'boolean') {
      page[index as keyof Subroute] = false;
    } else {
      page[index as keyof Subroute] = null;
    }
  }
}

const mainEl = ref(null);
const direction = computed(() => store.getters.GET_DIRECTION);
function overflowH() {
  mainEl.value.style.overflow = 'hidden';
}
function overflowN() {
  mainEl.value.style.overflow = '';
}

onBeforeRouteUpdate(async (to, from, next) => {
  resetState();
  resetScroll();
  next();
});

const flowIsFetched = ref(false);
async function reload(): Promise<void> {
  const urlQuery = new URL(window.location.href).searchParams;
  const token = urlQuery.get('token');
  let res = null;

  if (token) {
    store.commit('SET_KEY', token);
    res = await postData(Endpoint.Onboarding, {
      Key: token,
    });
  } else {
    res = await getData(Endpoint.Onboarding);
    if (!res?.isSuccess) {
      responseError(res.errors);
      return;
    }
  }

  store.commit('SET_USER_PROPS', {
    // reset some of the persisted login steps
    skipNemId: res.skipNemId,
    skipCprNumber: res.skipCprNumber,
    skipPensionsInfo: res.skipPensionsInfo,
  });
  flowIsFetched.value = true;
}

onMounted(() => {
  const el = document.getElementById('app');
  el.style.height = '-webkit-fill-available';
});
onBeforeUnmount(() => {
  const el = document.getElementById('app');
  el.style.height = '';
  el.children[0].classList.remove('customH');
});
</script>

<style lang="scss" scoped>
.customH {
  height: 100%;
  height: -webkit-fill-available;
}
#onboardingContainer {
  @media (min-width: 1000px) and (min-height: 700px) and (max-height: 1380px) {
    padding-top: 3rem;
  }
}
</style>
<style lang="scss">
#onboardingContainer {
  & [data-test='radio-image'],
  & [data-test='checkbox-image'] {
    grid-template-rows: minmax(min-content, 160px) !important;
    @media (min-width: 1000px) and (max-height: 900px) {
      grid-template-rows: minmax(min-content, 140px) !important;
    }
  }
  & [data-test='radio-image'],
  & [data-test='checkbox-image'] {
    [data-test='imgfillup'] {
      max-width: 8rem;
      @media (min-width: 1000px) and (max-height: 900px) {
        max-width: 7rem;
      }
    }
  }
}
</style>
