import { Endpoint } from './apiHelper';

interface imageOnAndOff {
  checkedImgUrl: string;
  uncheckedImgUrl: string;
}

export interface globalStyles {
  'logo-gradient': string;
  'pink-payment-category': string;
  'yellow-payment-category': string;
  'red-payment-category': string;
  'brown-payment-category': string;
  'green-payment-category': string;
  'default-payment-category': string;
  'dashboard-background': string;
  'color-primary': string;
  'color-primary-shade': string;
  'title-line-height': string;
  'font-title-1': string;
  'font-title': string;
  'font-body': string;
  'text-uppercase': string;
  'text-color-dark': string;
  'text-color-light': string;
  'text-color-lightest': string;
  'text-danger': string;
  'border-color-dark': string;
  'border-color-light': string;
  'border-color-lightest': string;
  'bg-color-light': string;
  'bg-color-dark': string;
  'logo-bar-color': string;
  'body-line-height': string;
  'progress-bar-color': string;
  'color-bar-1': string;
  'color-bar-2': string;
  'side-bar-color': string;
  'slider-background': string;
  'chat-user-bg': string;
  'button-corner-radius': string;
  'button-glow': string;
  'button-label-color': string;
  'button-label-hover-color': string;
  'button-background': string;
  'button-background-hover': string;
}

export interface Whitelabel {
  metaData: {
    browserTabName: string;
    companyName: string;
    externalBookAMeetingUrl: string;
  };
  styles: globalStyles;
  dashboardConfiguration: {
    overviewPage: {
      show: boolean;
      hasGuidedTour: boolean; // this will grow later
      dynamicFeatureCompType: 'share' | 'insurance';
    };
    savingsPage: {
      show: boolean;
      hasBookMeetingTile: boolean;
    };
    insurancesPage: {
      show: boolean;
      detailedInsurance: boolean;
      childrenRoutes: Record<string, boolean>;
      showUseInsurance: boolean;
    };
    healthPage: {
      show: boolean;
    };
    payoutsPage: {
      show: boolean;
    };
    advisoryPage: {
      show: boolean;
      hasBookMeetingButton: boolean;
      hasBookMeetingTab: boolean;
    };
    planningPage: {
      show: boolean;
    };
  };
  iconsAndImages: {
    logo: string;
    logoSmall: string;
    logoOnboarding: string;
    advisorIcon: string;
    questionIcon: string;
    logoutIcon: string;
    onboarding: {
      retiredPage: {
        homepageUrl: string;
      };
      retirementPage: {
        radioButtons: imageOnAndOff[];
      };
      signupPage: {
        fillUpImage: imageOnAndOff;
      };
      incomePage: {
        fillUpImage: imageOnAndOff;
      };
      detailsPage: {
        radioButtons: imageOnAndOff[];
      };
      dreamsPage: {
        radioButtons: imageOnAndOff[];
      };
      pensionAgePage: {
        // nothing for now
      };
      loginPage: {
        nemIdIcon: string;
      };
    };
    dashboard: {
      horisontalMenu: {
        messagesIcon: string;
      };
      verticalMenu: {
        overviewIcon: string;
        savingsIcon: string;
        insurancesIcon: string;
        healthIcon: string;
        payoutsIcon: string;
        advisoryIcon: string;
        planningIcon: string;
        preferencesIcon: string;
        logoutIcon: string;
      };
      shareUsOnSocialIcon: string;
      insurancesIcons: {
        insuranceCoverage: string;
        insuranceDeath: string;
        insuranceCritical: string;
        insuranceHealth: string;
        insuranceCertificate: string;
        insuranceOther: string;
        insuranceAccident: string;
      };
      healthPage: {
        treatementIcon: string;
        psyCounselingIcon: string;
        hospitalSpecialistIcon: string;
        rehabCrisisIcon: string;
      };
      advisoryPage: {
        advisoryIcons: {
          savingsIcon: string;
          riskIcon: string;
          costcheckIcon: string;
          insuranceIcon: string;
          payoutsIcon: string;
        };
        stopLightIcons: {
          red: string;
          yellow: string;
          green: string;
        };
        toggleTileIcon: string;
      };
      planningPage: {
        ageIcon: string;
        incomeIcon: string;
      };
      preferencesPage: {
        pensionAgeIcon: string;
        monthlyIncomeIcon: string;
        incomeIcon: string;
        riskProfileIcon: string;
      };
      profilePage: {
        contactInfoIcon: string;
        consentIcon: string;
        documentsIcon: string;
      };
    };
  };
}

export function getStyles(): Promise<Whitelabel> {
  return fetch(Endpoint.Whitelabeling).then((res) => res.json());
}
