import { LanguageRepository } from '@adapters/i18n-local.repository';
import type { keyedString, keyedStringWithArrays } from './base/helperTypes';
import { get } from 'lodash-es';
export const lang = new LanguageRepository();

interface LanguageServiceParameters {
  locale?: string;
  getKeysInsteadOfText?: boolean;
}
export class LanguageService {
  text: keyedStringWithArrays;
  public getKeysInsteadOfText: boolean;
  constructor({
    locale = 'da',
    getKeysInsteadOfText = false,
  }: LanguageServiceParameters = {}) {
    this.text = lang.getTranslations(locale);
    this.getKeysInsteadOfText = getKeysInsteadOfText;
  }

  updateTranslations(locale: string): void {
    // this.text = lang.getTranslations(locale);
  }

  /**
   * Get localisation of key
   * @param keyPath Nested property to get from localisation e.g. 'A.B.C'
   */
  getTranslation(
    keyPath: string
  ):
    | string
    | keyedString
    | keyedStringWithArrays
    | keyedStringWithArrays[]
    | undefined {
    if (this.getKeysInsteadOfText) {
      return keyPath;
    }
    const text = get(this.text, keyPath, undefined);
    //if (typeof text !== 'string') {
    return text;
  }

  /**
   * Get localisation of key
   * @param keyPath Nested property to get from localisation e.g. 'A.B.C'
   */
  // fpGetTranslation(
  //   keyPath: string,
  // ): Result<string | keyedString | keyedStringWithArrays | keyedStringWithArrays[]> {
  //   if (this.getKeysInsteadOfText) {
  //     return keyPath;
  //   }
  //   const text = get(this.text, keyPath, undefined);
  //   //if (typeof text !== 'string') {
  //   if (text === undefined) {
  //     return { ok: false, errors: keyPath };
  //   }
  //   //  return 'NOT A FINAL PATH';
  //   //}
  //   return { ok: true, res: text };
  // }

  /**
   * Get localisation of key
   * @param keyPath Nested property to get from localisation e.g. 'A.B.C'
   * @param params properties to interpolate e.g. '{name: "John Doe", age: 12}'
   */
  getDynamicTranslation(
    keyPath: string,
    params: Record<string, string>
  ):
    | string
    | keyedString
    | keyedStringWithArrays
    | keyedStringWithArrays[]
    | undefined {
    if (this.getKeysInsteadOfText) {
      return keyPath;
    }

    const val = this.getTranslation(keyPath);
    if (typeof val !== 'string') {
      return val;
    }

    const interpolated = this.interpolate(val, params);

    return interpolated;
  }

  private interpolate(val: string, params: Record<string, string>): string {
    let interpolated = val;
    for (const [key, sub] of Object.entries(params)) {
      const pattern = `{{${key}}}`;
      const re = new RegExp(pattern, 'g');
      interpolated = interpolated.replace(re, sub);
    }
    return interpolated;
  }
}

export const i18n = new LanguageService();

type ElementWrap = { element: string; props: Record<string, string | number> };
