<template>
  <div class="w-full max-w-4xl mx-auto mt-auto mb-8 lg:mb-16 relative p-0 text-center flex justify-center">
    <div class="inset-0 absolute flex items-center justify-between flex-grow mx-4 z-10">
      <checkedFxDiv
        v-for="(el, i) in routes.length"
        :key="routes[i]"
        :checked="i < index"
        :active="i === index"
        :link="routes[i]"
        :class="[i > index ? 'pointer-events-none opacity-50' : '']"
        :style="{ width: 100 / routes.length }"
        class="progressCircle"
      />
    </div>
    <div class="progress w-full mx-4" style="height: 3px">
      <div
        class="progress-bar h-full transition-transform origin-left"
        role="progressbar"
        :style="`transform: scaleX(${step}%)`"
      ></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, defineProps } from 'vue';
  import { useRouter } from 'vue-router';
  import checkedFxDiv from './checkedFxElement.vue';

  const props = defineProps({
    routes: {
      type: Array,
      required: true,
    },
  });

  const router = useRouter();

  const index = computed(() => {
    return props.routes.findIndex((el) => el.toLowerCase() === router.currentRoute.value.name);
  });
  const step = computed(() => {
    return Math.round((100 / props.routes.length) * (index.value + index.value * 0.16));
  });
</script>

<style lang="scss" scoped>
  .progressCirclesContainer {
    height: 2px;
  }

  .progressCircle {
    background: var(--progress-bar-color);
  }
  .progress-bar {
    background: var(--progress-bar-color);
  }
  .progress {
    background: rgb(233, 233, 233);
  }
</style>
