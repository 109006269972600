<template>
  <div
    id="leftSideBar"
    class="w-full max-w-sm items-center flex-col p-3 customGradient hidden lg:flex h-screen dropRightShadow text-center sticky top-0 text-white"
    data-test="side-bar"
  >
    <div
      class="mt-12 ml-12 flex items-center justify-center self-start logo w-full"
    >
      <img class="w-full" :src="logoUrl" />
    </div>
    <!-- <div class="mt-auto flex flex-col items-center text-center mb-10">
      <a
        href="https://virksomhedsregister.finanstilsynet.dk/virksomhed-under-tilsyn.html?v=F2FB30F9-9B00-EA11-814F-00505680286D"
        target="_blank"
      >
        <img src="/svg/finansStamp.svg" style="max-width: 9rem" class="mx-auto max-w-xs mb-8 flex" />
        <div class="mt-5">
          <h3 class="text-xl">
            {{ i18n.getTranslation('ONBOARDING.APPROVED_FOR_FINANCE.TITLE') }}
          </h3>
          <p class="mt-2">Finanstilsyn ID: 36203</p>
        </div>
      </a>
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const logoUrl = computed(
  () => store.getters.GET_WHITELABEL.iconsAndImages.logoOnboarding
);
</script>

<style lang="scss" scoped>
.logo {
  width: var(--side-logo-bar-width, 15rem);
  max-height: 9rem;
}
.customGradient {
  background: linear-gradient(
    -30deg,
    var(--color-bar-1) 0%,
    42%,
    var(--color-bar-1) 42%,
    var(--color-bar-2) 42%,
    var(--color-bar-2) 45%,
    var(--color-bar-1) 100%
  );
}
.brandGradient {
  background: var(--logo-gradient);
}
</style>
