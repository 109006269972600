import { responseError } from '../../custom/Methods';
import { UserRepository } from '@core/ports/user.repository';
import type {
  UserRetirementPreferences,
  PreferencesModel,
  UserInfoModel,
} from '@core/models/user.model';
import { Endpoint, fetchData, postData } from './apiHelper';
import type { APIResponse } from '@core/models/apiResponse.model';
import type { UserAuthModel } from '@core/models/user.model';

export class UserWebRepository extends UserRepository {
  logout(): Promise<void> {
    return fetchData(Endpoint.Logout).then(() => {
      console.log('Logged out');
      return;
    });
  }
  async getAuthState(): Promise<UserAuthModel> {
    try {
      const res = await fetchData(Endpoint.AuthState);

      if (!res?.isSuccess && !res.data) {
        responseError(res.error);
      } else return res.data;
    } catch (error) {
      console.log(error);
      responseError(error);
    }
  }
  getUser(): Promise<UserInfoModel> {
    return fetchData(Endpoint.UserInfo).then((res) => {
      // console.log('Hey', x);
      if (res === undefined) {
        throw Error('What undefiend?');
      }
      if (res && res.isSuccess === false) {
        throw res.errors[0];
      }
      return res.data;
    });
  }
  deleteUser(name: string): Promise<any> {
    return postData(Endpoint.DeleteUser, { firstName: name });
  }

  // /api/dashboard/dreamscard
  getPreferences(): Promise<PreferencesModel> {
    // return of(preferences);
    return fetchData(Endpoint.UserPreferences).then((x) => {
      if (x === undefined) {
        console.error('Undefined??');
        throw Error('uh, oh');
      }
      if (x.isSuccess === false) {
        console.error(x.errors);
        throw Error('uh, oh');
      }
      return x.data;
      // return {
      //   wantedPensionIncome: x.data.wantedPensionIncome,
      //   wantedPensionAge: x.data.wantedPensionAge,
      //   wantedRisk: x.data.wantedRisk,
      // };
    });
  }
  updatePreferences(
    preferences: UserRetirementPreferences
  ): Promise<APIResponse<unknown>> {
    // Send preferences to back-end
    const data = {
      wantedPensionIncome: preferences.amount,
      wantedPensionAge: preferences.retirementAge,
      wantedRisk: preferences.risk,
      monthlyIncome: preferences.monthlyIncome,
    };
    return postData(Endpoint.UserPreferences, data);
  }

  getProfile(): Promise<any> {
    return fetchData(Endpoint.Profile);
  }
  updateProfile(data: any): Promise<any> {
    return postData('/api/dashboard/user/edit', data);
  }
}

// interface TableResponse {
//  header: string;
//  table: {
//    headers: string[];
//    groups: {
//      item1: string;
//      item2: string[][];
//    }[];
//  };
// }
