import { createRouter, createWebHistory } from "vue-router";
// import { store as storeOnboard }  from './store';
import { store } from "./store";
import { i18n } from "@core/i18n.service";
// import { analytics } from '@core/analytics';
import { routes } from "./routes";

const myRouter = createRouter({
  history: createWebHistory("dashboard"),
  routes,
});
export default myRouter;

myRouter.beforeEach((to, from, next) => {
  if (to.query?.textKeys) {
    i18n.getKeysInsteadOfText = true;
  }

  if (from.name) {
    store.commit("SET_HISTORY", from.name);
  }
  next();
});
